<template>

  <app-modal-add-tax
    :show="showModalTax"
    @close="showModalTax = false"
    @refresh="refresh"
  />

 <!-- Modal Active Business-->
  <app-modal
    v-if="hasActiveBusiness"
    :show="showModalActive"
    @close="showModalActive = false"
    :textButtonCancel="$t('payment.info.active_later')"
  >
    <template v-slot:title>{{
      $t("payment.info.active_your_account_title")
    }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("tax.active_your_account_description") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @onClick="$router.push({ name: 'business' })"
        @click="showModalActive = false"
        :showf70Icon="false"
        :primary="false"
        class="
          sm:ml-3 sm:w-auto
          bg-primary
          hover:bg-primary-100
          text-white
          outline-none
        "
      >
        {{ $t("payment.info.active_account") }}
      </app-button>
    </template>
  </app-modal>

  <div>
    <app-table
      :loading="apiPaginationLoading"
      :apiResponse="apiPaginationResponse?.data ?? []"
      @params-changed="paramsChanged"
      @empty-button-clicked="createTax"
      emptyIcon="app-icon-empty-tax"
      :emptyTitle="$t('tax.empty.title')"
      :emptyButton="$t('tax.new_tax')"
      :emptyDescription="$t('tax.empty.description')"
      :filters="[
        {
          key: 'filter[name]',
          type: 'text',
          placeholder: $t('tax.filter.placeholder_name'),
          value: '',
        },
        {
          key: 'filter[description]',
          type: 'text',
          placeholder: $t('tax.filter.placeholder_description'),
          value: '',
        },
        {
          key: 'filter[rate]',
          type: 'number',
          placeholder: $t('tax.filter.placeholder_rate'),
          value: '',
        },
        {
          key: 'filter[created_at_between]',
          type: 'date',
          placeholder: $t('wallet.payout.date'),
          value: '',
        },
      ]"
    >
      <template v-slot:title>
        <div>
          <p class="font-medium">
            {{ $t("tax.tax_title") }}
          </p>
        </div>
      </template>
      <template v-slot:button>
        <app-button
          width="w-1/8"
          :showf70Icon="false"
          @click="createTax"
          :loading="apiPaginationLoading"
        >
          <template v-slot:icon>
            <app-icon-outline name="PlusIcon" class="h-6 w-6 text-white mr-2" />
          </template>
          {{ $t("tax.new_tax") }}
        </app-button>
      </template>
      <template v-slot:header>
        <th class="font-bold">{{ $t("tax.table_header.tax_name") }}</th>
        <th class="font-bold">{{ $t("tax.table_header.tax_description") }}</th>
        <th class="font-bold">{{ $t("tax.table_header.tax_rate") }}</th>
        <th class="font-bold">{{ $t("tax.table_header.tax_date_created") }}</th>
        <th class="font-bold">{{ $t("tax.table_header.action") }}</th>
      </template>
      <template v-slot:body="data">
        <td>
          {{ data.model?.name }}
        </td>
        <td>
          {{ data.model?.description }}
        </td>
        <td>
          {{ data.model?.rate }}
        </td>
        <td>
          {{ $moment(data.model.created_at).format("DD MMM YYYY hh:mm a") }}
        </td>
        <td>
          <div class="flex space-x-2 text-gray-400">
            <p @click.stop="onClickTax(data.model)">
              {{ $t("tax.edit") }}
            </p>
            <p @click.stop="openDeleteConfirmation(data.model)">
              {{ $t("tax.delete") }}
            </p>
          </div>
        </td>
      </template>
    </app-table>
  </div>

  <app-modal
    :show="showDeleteConfirmation"
    @close="showDeleteConfirmation = false"
  >
    <template v-slot:title>{{ $t("tax.delete_tax") }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("tax.delete_tax_confirmation") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @onClick="deleteTax(selectedTax)"
        :showf70Icon="false"
        :primary="false"
        type="button"
        class="
          sm:ml-3 sm:w-auto
          bg-error
          hover:bg-error
          text-white
          outline-none
        "
      >
        {{ $t("tax.delete") }}
      </app-button>
    </template>
  </app-modal>
</template>

<script>
import AppModalAddTax from "../../../components/item/app-modal-add-tax.vue";
import Business_status from "@/utils/const/business_status";
export default {
  components: { AppModalAddTax },
  data() {
    return {
      filters: {},
      showModalActive: false,
      showModalTax: false,
      showDeleteConfirmation: false,
      selectedTax: {},
    };
  },
  computed: {
    apiPaginationResponse() {
      return this.$store.getters["taxStore/apiPaginationResponse"];
    },
    apiPaginationLoading() {
      return this.$store.getters["taxStore/apiPaginationLoading"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    hasActiveBusiness() {
      if (this.business.business_status_id == null) {
        return false;
      }
      return this.business.business_status_id != Business_status.APPROVED;
    },
  },
  mounted() {
    this.fetchListTax();
  },
  methods: {
    paramsChanged(filters, queryParams) {
      this.filters = filters;
      this.fetchListTax(this.filters);
    },

    async deleteTax(tax) {
      this.showDeleteConfirmation = false;

      const result = await this.$store.dispatch("taxStore/deleteTax", tax);
      if (!this.$lodash.isNil(result)) {
        this.refresh();
      }
    },
    openDeleteConfirmation(tax) {
      this.selectedTax = tax;
      this.showDeleteConfirmation = true;
    },
    createTax(){
      this.hasActiveBusiness ? this.onClickHasActiveBusiness() : this.onClickTax()
    },
    onClickHasActiveBusiness(){
      this.showModalActive = !this.showModalActive;
      this.showModalTax = !this.showModalTax;
    },
    onClickTax(tax) {
      this.showModalTax = !this.showModalTax;

      if (tax == null) {
        this.$store.commit("taxStore/initTax");
      } else {
        this.$store.dispatch("taxStore/getTaxById", tax._id);
      }
    },
    fetchListTax(filter) {
      this.$store.dispatch("taxStore/retrieveTax", filter);
    },
    refresh() {
      this.fetchListTax();
    },
  },
};
</script>